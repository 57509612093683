import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../components/styles/seance.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const Acces = () => (
  <Layout>
    <Seo />
    <HeaderRandom />
    <div id='title'>
      <h1>ACCESS BARS®</h1>
    </div>

    <div className='container division'>
      <div className='row'>
        <div className='col-md-6'>
          <p>
            ACCESS BARS®
            Méthode qui a été développé par
            Gary DOUGLAS
            (access consciousness ®)
          </p>

          <p><b>Qu'est ce que c'est ???</b></p>
          <p>Une méthode juste formidable qui permet de nettoyer notre disque dur interne de pensées dites limitantes, qui tournent en boucle, réduit le stress, l’angoisse. C’est un grand nettoyage de printemps.</p>
          <p><b>Par quel procédé ?</b></p>
          <p>Il s’agit de toucher 32 points sur la tête  en douceur alors que vous êtes confortablement allongé, vous entrez ainsi dans un état de relaxation incomparable pouvant aller jusqu’à l’endormissement.
            A la fin de la séance on peut constater une sensation de calme et de paix intérieur.
            Puis, un meilleur sommeil, une action sur le stress...
            Chacun a un ressenti qui lui est propre, il y a toujours un bénéfice.
            La séance dure en général 1 heure.
          </p>

          <p><b>MTVSS (access consciousness )</b></p>
          <p>
            Cette méthode consiste en une apposition des mains sur le corps.
            Afin de renforcer le système immunitaire, soulager les articulations, le système respiratoire, système reproductif, système dermo-facial et le transit… La séance dure 1 heure.
          </p>

          <p><i>Cette méthode est une méthode énergétique et ne remplace pas la visite chez le médecin </i></p>

        </div>
        <div className='col-md-6 sticky'>
          <StaticImage src='../img/head.jpg' width={555} height={369} placeholder='blurred' alt='access bars head' />
        </div>
      </div>
    </div>

    <div className='container division'>
      <div className='row'>
        <iframe height={315} title='Hypnose video' src='https://www.youtube.com/embed/Cc4Kx6bJSvM?cc_load_policy=1&loop=1&modestbranding=1&color=white&rel=0&autoplay=1&mute=1' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen className='center block full video' />
      </div>
    </div>

    <div className='container division'>
      <div className='row'>
        <div className='col-md-6' />
        <div className='col-md-6 t-right'>
          <StaticImage src='../img/access.jpg' placeholder='blurred' alt='access bars' width={175} height={311} />
        </div>
      </div>
    </div>
  </Layout>
);

export default Acces;
